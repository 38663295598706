<!-- Mto expansible Presupuestos Expedientes -->

<template>
  <div class="exp_pres_detalle_XM" v-if="schema">
    <!-- <v-sheet :elevation="4"> -->
      <!-- Botones Mto -->
      <div class="pb-2 d-flex justify-left">
        <!-- Mto -->
        <btramto
          v-if="is_abueloEdit"
          :btra_permisos="schema.btra"
          :btra_particular="Entorno.btra"
          btra_modulo="xm"
          :estado="estado"
          @onEvent="event_btra_Mto"
        >
        </btramto>
      </div>

      <!-- Controles del Mto -->
      <!-- <div class="contenedor"> -->
        <div style="display:flex">
          <v-text-field style="flex:50%"                   
            v-bind="$input"
            v-model="schema.ctrls.name_prod_id.value"
            :label="schema.ctrls.name_prod_id.label"
            readonly>                    
          </v-text-field>

          <v-text-field
            style="flex:50%"
            v-bind="$input"
            v-model="schema.ctrls.namea.value"
            :label="schema.ctrls.namea.label"
            :disabled="!is_edit"
          >
          </v-text-field>
        </div>

        <div class="conflex">
          <div
            class="conflex"
            style="padding: 0 10px; align-items:center;justify-content: space-between;flex:30%"
          >
                       
            <v-checkbox
              v-bind="$input"
              v-model="schema.ctrls.sup.value"
              label="Suplido"
              color="green"
              true-value="S"
              false-value="N"
              :disabled="!is_edit"
              @change="changeSuplido">            
            </v-checkbox>

          </div>

          <v-text-field
             style="flex: 0 0 70px"
            v-bind="$input"
            v-model="schema.ctrls.und.value"
            :label="schema.ctrls.und.label"
            :disabled="!is_edit"
            @blur="recalc_imp()"
          >
          </v-text-field>

          <!-- <v-combobox style="flex:5%"
          v-bind="$select"
          :disabled="!is_edit"
          :items="itemsIva"
          v-model="schema.ctrls.iva.value"
          label="IVA">
          </v-combobox> -->
         <v-select  style="flex: 0 0 80px"
            v-model="schema.ctrls.iva.value"
            v-bind="$select"
            label="IVA"
            :items="get_ivas"
            :disabled="!is_edit || schema.ctrls.sup.value=='S'"
            item-value="value"
          ></v-select>
          <!-- <v-input_n
            style="flex:5%"
            v-bind="$input"
            simbolo="%"
            v-model="schema.ctrls.iva.value"
            :label="schema.ctrls.iva.label"
            :disabled="!is_edit"
             @blur="recalc_imp()"
          >
          </v-input_n> -->
         

          <!-- @onEvent="event_pvp($event)" -->
          <v-input_n
             style="flex: 0 0 100px"
            v-bind="$input"
            v-model="schema.ctrls.pvp.value"
            :label="schema.ctrls.pvp.label"
            :disabled="!is_edit"
            @blur="recalc_imp()"
          >
          </v-input_n>

          <vtlabel
             style="flex: 0 0 100px"
            stilot="text-align:right"
            v-bind="$input"
            :txt="schema.ctrls.imp.value|num(2)"
            :label="schema.ctrls.imp.label"
         
          >
          </vtlabel>
        </div>
      <!-- </div> -->
    <!-- </v-sheet> -->
  </div>
</template>

<script>
import { mixinMto } from "@/mixins/mixinMto.js";
import { mixinMtoArray } from "@/mixins/mixinMtoArray.js";
import plugs from "@/common/general_plugs";
const btramto = () => plugs.groute("btramto.vue", "comp");

export default {
  mixins: [mixinMto, mixinMtoArray],
  components: { btramto },
  props: {
    padre: { type: String, default: "" },
    accion: { type: [Number, String], default: "" },
    accionRow: { type: Object, default: () => {} },
    disparo: { type: Boolean, default: false },
    componenteTipo: { type: String, default: "M" },
    Entorno: {
      type: Object,
      default: function() {
        return JSON.parse(JSON.stringify(this.$cfg.base.M));
      }
    }
  },

  data() {
    return {
      schema: null,
      api: "exp_presLin_M",
      stName: "stLibresMX",
      disparoFinder: false,



      // itemsRt:[
      //   {id:"1", name:"IVA",  tipos:[{value:"21.0",text:"21%"}, {value:"10.0",text:"10%"},{value:"0.0",text:"0%"},{value:"4.0",text:"4%"}]},
      //   {id:"2", name:"IGIC", tipos:[{value:'3.0',text:"3%"}, {value:'7.0',text:"7%"},{value:'0.0',text:'0%'},{value:'9.5',text:'9.5%'},{value:'13.5',text:'13.5%'},{value:'20.0',text:'20%'}]},
      //   {id:"3", name:"IPSI", tipos:[{value:'4.0',text:"4%"}, {value:'1.0',text:"1%"},{value:'2.0',text:'2%'},{value:'8.0',text:'8%'},{value:'10.0',text:'10%'},{value:'0.5',text:'0.5%'}]},
      //   {id:"4", name:"IC",   tipos:[{value:'0.0',text:"0%"}]}
      // ]
    };
  },
  computed:{
    get_ivas() {  
      console.log("padre:",this.padre)
      let M=this.$store.state[this.padre].padre;
      M=this.$store.state[M];
      console.log("get-ivas mx:", M.schema.ctrls.rt.value)
      if (M.schema.ctrls.rt.value )
              return this.$store.state.datos_iniciales.rTribut[M.schema.ctrls.rt.value].tipos;
      return[];
      // console.log("iva-inicial:", this.schema.ctrls.rt.value)
      // //let cabecera=this.$store.state[this.padre].padre
      // console.log( "ivas: ",this.$store.state.datos_iniciales.rTribut[this.$store.state[ this.$store.state[this.padre].padre].this.schema.ctrls.rt.value].tipos.map(i=>Number(i.value)))
      // return this.$store.state.datos_iniciales.rTribut[this.$store.state[ this.$store.state[this.padre].padre].schema.ctrls.rt.value].tipos.map(i=>i);
    }
  },
  methods: {

    ini_data() {
      console.log("DEV " + this.api + " ********************** (ini_data)");

      // guardo del store en variables locales
      this.schema = this.$store.state[this.stName].schema;

      // configuro Entorno
      this.Entorno.header.header = false;
      this.Entorno.btra = "XM";
    },
    guardar_nuevo_after(record) {
      // ordenar registros despues de alta
      // el nuevo pasa al C:ltimo    
      if (!record.id) return;

      const padre=this.$store.state[this.stName].padre
      let arrayRecords=JSON.parse(JSON.stringify(this.$store.state[padre].records));
      console.log("arrayRecords", arrayRecords)
      //encuentro el index del item en la array
      let index = arrayRecords.findIndex(e => e.id == record.id);
      if (index<0) return;
      let item=arrayRecords[index];
      arrayRecords.splice(index,1);
      arrayRecords.push(item)

      arrayRecords=arrayRecords.map((e,index) => {e.orden=index; return e});
      this.$store.commit('data2State', { modulo:padre, prop:'records', value:arrayRecords});
    },
    record_cargado(){
   
     console.log("xm cargado:", this.schema.ctrls.iva.value)
     if (this.schema.ctrls.iva.value) return;
     let M=this.$store.state[this.padre].padre;
     M=this.$store.state[M];
     this.schema.ctrls.iva.value=M.schema.ctrls.iva_general.value;
     console.log("xm cargado:", this.schema.ctrls.iva.value, M.schema.ctrls.iva_general.value)
    },
    //
    event_pvp(evt) {
      console.log("*** onEvent pres_funes_detalle. Accion: ", evt, " ***");

      if (this.$isFunction(this[evt.accion])) {
        this[evt.accion](evt);
        return;
      }
    },

    // configuraciC3n particular de los campos del grid
    get_field(item, header) {
      if (typeof item[header.value] === "undefined") return;

      if (header.value === "sp")
        return item["sp"] === "1" || item["sp"].toUpperCase() === "S"
          ? "S"
          : "N";
      return item[header.value];
    },

    // recalculo importe linea detalle
    recalc_imp() {
      let r= this.schema.ctrls.und.value * this.schema.ctrls.pvp.value;
      this.schema.ctrls.imp.value= Math.round(Number((r * 100).toFixed(2))) / 100;
    },
    
    validate_particular() {
      // get iva_general de cabecera de presupuestos del schema
      let cabecera = this.$store.state[this.padre].padre;
      let rt_ivas =this.$store.state.datos_iniciales.rTribut[this.$store.state[cabecera].schema.ctrls.rt.value].tipos.map(i=>Number(i.value));
      console.log("ivas:",rt_ivas)
      let iva = Number(this.schema.ctrls.iva.value);


     // validar si es factura de suplido el iva y suplido
     let M=this.$store.state[this.padre].padre;
     M=this.$store.state[M];


     // caso presupuesto/factura de SUPLIDO chequeo iva y sup
     if(M.schema.ctrls.suplido.value=="1" && this.schema.ctrls.sup.value!="S"){
           this.$root.$alert.open('Este tipo de presupuesto/factura SUPLIDO solo admite Suplidos', 'error' )
           return false;
     }

     // comprueba si es suplido y ha metido iva
     if(this.schema.ctrls.sup.value=="S" && Number(this.schema.ctrls.iva.value)!=0) {
         this.$root.$alert.open('IVA/SUPLIDO incompatible', 'error' )
        return false;
     }

     // Comprueba si no ha dejado el producto en blanco 
      if (this.schema.ctrls.name_prod_id.value == "") {
        this.$root.$alert.open("Debe introducir producto/servicio", "error");
        return false;
      }
      
  

     // Comprueba si no ha intoroducido unidades 
      if (Number(this.schema.ctrls.und.value==0 ||Number(this.schema.ctrls.und.value>5000 || isNaN(this.schema.ctrls.und.value)))) {
        this.$root.$alert.open("Error en unidades de producto", "error");
        return false;
      }
      // comprueba si ha introducido precio
      if (isNaN(this.schema.ctrls.pvp.value)) {
        this.$root.$alert.open("Error en precio de producto", "error");
        return false;
      }
      //pendiente: los records estan en el padre y en la cabera, que es mejor coger?
      
      // Ver si hay productos duplicados
      let records= this.$store.state[this.padre].records;
      if( records.find(element => (element.prod_id==this.schema.ctrls.prod_id.value
                                  && element.namea==this.schema.ctrls.namea.value
                                  && element.id!=this.schema.ctrls.id.value)))
                                  {
                                    this.$root.$alert.open("Producto/servicio duplicado","error");
                                    return false;
                                  }
      console.log("ivas:",rt_ivas, iva)
      if (rt_ivas.includes(iva)) return true;

     this.$root.$alert.open(
          `Revise: Tipo de Impuesto ${this.$store.state.datos_iniciales.rTribut[this.$store.state[cabecera].schema.ctrls.rt.value].name}   producto ${iva}%`,
          "error");
     return false;
    },


    //
    changeSuplido(val) {      
      if (val== 'S') {        
        this.schema.ctrls.iva.value= "0.0";
        return;
      }

      this.schema.ctrls.iva.value= this.miStAbuelo.schema.ctrls.iva_general.value;
    }
  }
};
</script>
